<template lang="pug">
section.intro
  ShootingStar.shooting-star
  .scroll-indicator
    Swipe.swipe
    Scroll.scroll
    DownArrow.arrow
  .stars
    Stars.static-stars
    AmbientStars.ambient-stars
  .text
    Moon.moon
    LittleParadowskiStory.little-paradowski-story
    p.text-1
      span.line-1 It won’t happen once and for all.
      br
      span.line-2 But it will happen for all just once.
    p.text-2
      span.line-1 You’ll have to re-emerge.
    p.text-3
      span.line-1 This year, etiquette changed on us.
      br
      span.line-2 And when these peculiar times are over 🤞, it will likely change again.
    p.text-4
      span.line-1 So we’re offering a few ...
  .day
    span.gradient
    //- Rays.rays
    Sun.sun
  h1.title Simple Phrases
  p.byline
    span.line-1 A Manners Manual for
    br
    span.line-2 Life After Lockdown
  .house
    House
</template>

<script>
import DeviceDetector from "device-detector-js";
import Stars from "../assets/IntroScene/a-d/stars.svg";
import AmbientStars from "../assets/IntroScene/ambient-stars.svg";
import LittleParadowskiStory from "../assets/IntroScene/a-d/a-little-pc-story.svg";
import Moon from "../assets/IntroScene/a-d/moon.svg";
import DownArrow from "../assets/IntroScene/down-arrow.svg";
import Swipe from "../assets/IntroScene/swipe-text.svg";
import Scroll from "../assets/IntroScene/scroll-text.svg";
import ShootingStar from "../assets/IntroScene/a-d/shooting-star.svg";
// import Rays from "../assets/IntroScene/rays.svg";
import Sun from "../assets/IntroScene/e-h/sun.svg";
import House from "../assets/IntroScene/e-h/building.svg";

import AudioManager from "../AudioManager";

import gsap from "../gsap";
import analytics from "../mixins/analytics";

const deviceDetector = new DeviceDetector();
// const device = deviceDetector.parse(navigator.userAgent);
const isTouch = deviceDetector.isToucheEnabled(navigator.userAgent);

export default {
  name: "IntroScene",
  components: {
    Stars,
    AmbientStars,
    ShootingStar,
    LittleParadowskiStory,
    Moon,
    Sun,
    House,
    Swipe,
    Scroll,
    DownArrow,
  },
  mixins: [analytics],
  data() {
    return {
      sceneTl: null,
      moonTl: null,
      storyTl: null,
      staticStarsTl: null,
      ambientStarsTl: null,
      textTl: null,
      dayTl: null,
      arrowTl: null,
      scrollTl: null,
      sunTl: null,
      // raysTl: null,
      sectionTl: null,
      houseTl: null,
      shootinStartTl: null,
    };
  },
  mounted() {
    const swipe = this.$el.querySelector(".swipe");
    const scroll = this.$el.querySelector(".scroll");
    swipe.style.display = "none";

    if (isTouch != null) {
      swipe.style.display = "block";
      scroll.style.display = "none";
    }

    //section timeline
    this.houseTl = gsap.timeline();
    this.houseTl
      .fromTo(
        ".title",
        {
          autoAlpha: 0,
          y: "60vh",
        },
        {
          autoAlpha: 1,
          y: 0,
          delay: 31,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".byline",
        {
          autoAlpha: 0,
          y: "70vh",
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: "power3.inOut",
        },
        '<'
      )
      .fromTo(
        ".house",
        {
          autoAlpha: 0,
          y: "100vh",
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: "power3.inOut",
        },
        '<'
      )
      .to(".house, .byline, .title", {
        autoAlpha: 1,
        duration: 1,
      });

    //section timeline
    this.sectionTl = gsap.timeline();
    this.sectionTl
      .to(
        ".intro",
        {
          backgroundColor: "#fcf3eb",
          duration: 3,
          delay: 29,
          ease: "power3.inOut",
        }
      )
      .to(
        ".intro .gradient",
        {
          autoAlpha: 0,
          duration: 2
        },
        '<'
      )

    // sun timeline
    this.sunTl = gsap.timeline();
    this.sunTl
      .fromTo(
        ".intro .sun",
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 3,
          delay: 26,
          scale: 1,
          ease: "power3.inOut",
        }
      )
      .add(() => {
        if (this.sceneTl.scrollTrigger.direction == -1) {
          AudioManager.play("Wind");
        } else {
          AudioManager.play("Traffic");
        }
      })
      .fromTo(
        ".intro .sun",
        {
          y: 0,
        },
        {
          y: "-90%",
          duration: 3,
          scale: 1.2,
          ease: "power3.inOut",
        }
      );

    //rays timeline
    // this.raysTl = gsap.timeline();
    // this.raysTl
    //   .fromTo(
    //     ".intro .rays",
    //     {
    //       autoAlpha: 0,
    //     },
    //     {
    //       autoAlpha: 0.2,
    //       duration: 3,
    //       delay: 26,
    //       ease: "power3.inOut",
    //     }
    //   )
    //   .to(".intro .rays", {
    //     y: "-120vh",
    //     autoAlpha: 1,
    //     duration: 3,
    //     ease: "power3.inOut",
    //     rotation: 90,
    //   });

    //day timeline
    this.dayTl = gsap.timeline();
    this.dayTl.fromTo(
      ".intro .gradient",
      {
        autoAlpha: 0,
        y: 100,
      },
      {
        autoAlpha: 1,
        y: 0,
        duration: 3,
        delay: 20,
        ease: "power3.inOut",
      }
    );

    // scroll arrow
    this.arrowTl = gsap.timeline();
    this.arrowTl.fromTo(
      ".arrow",
      {
        y: 0,
      },
      {
        y: 10,
        duration: 0.5,
        // ease: "power3.inOut",
        repeat: -1 /* Aka infinite amount of repeats */,
        yoyo: true,
      }
    );

    // scroll indicator
    this.scrollTl = gsap.timeline();
    this.scrollTl
      .fromTo(
        ".scroll-indicator",
        {
          autoAlpha: 1,
          y: 0,
        },
        {
          autoAlpha: 0,
          y: "-100vh",
          duration: 3,
          delay: 0.5,
          ease: "power3.inOut",
        }
      )
      .add(() => {
        if (this.sceneTl.scrollTrigger.direction == 1) {
          AudioManager.play("Wind");
        }
      }, "<");

    // static stars
    this.textTl = gsap.timeline();
    this.textTl
      .fromTo(
        ".intro .text-1 .line-1",
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          delay: 8,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-1 .line-2",
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-1",
        {
          autoAlpha: 1,
          y: 0,
        },
        {
          autoAlpha: 0,
          y: -100,
          duration: 3,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-2 .line-1",
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-2",
        {
          autoAlpha: 1,
          y: 0,
        },
        {
          autoAlpha: 0,
          y: -100,
          duration: 3,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-3 .line-1",
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-3 .line-2",
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-3",
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .text-4 .line-1",
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 2,
          ease: "power3.inOut",
        }
      )
      .to(".intro .text-4 .line-1", {
        y: "-120vh",
        autoAlpha: 0,
        duration: 3,
        delay: 1,
        ease: "power3.inOut",
      });

    // static stars
    this.staticStarsTl = gsap.timeline();
    this.staticStarsTl
      .fromTo(
        ".intro .static-stars",
        {
          y: 0,
        },
        {
          immediateRender: true,
          y: "-130vh",
          duration: 29,
        }
      )
      .to(".intro .static-stars", {
        y: "-150vh",
        duration: 3,
        ease: "power3.inOut",
      });

    // shooting star
    this.shootinStartTl = gsap.timeline();
    this.shootinStartTl.fromTo(
      ".intro .shooting-star",
      {
        x: 0,
        y: 0,
      },
      {
        x: "-115vw",
        y: "150vh",
        duration: 1.5,
        delay: 13.5,
      }
    );

    // amnbient stars
    this.ambientStarsTl = gsap.timeline();
    this.ambientStarsTl
      .fromTo(
        ".intro .ambient-stars",
        {
          y: "-100vh",
        },
        {
          immediateRender: true,
          y: "-20vh",
          duration: 15,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .ambient-stars",
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          duration: 5,
          delay: 1,
          ease: "power3.inOut",
        }
      );

    // story title
    this.storyTl = gsap.timeline();
    this.storyTl
      .fromTo(
        ".intro .little-paradowski-story",
        {
          autoAlpha: 0,
          delay: 0.5,
          y: 300,
        },
        {
          autoAlpha: 1,
          immediateRender: true,
          y: 0,
          delay: 0.5,
          duration: 3,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .little-paradowski-story",
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          delay: 1,
          duration: 3,
          ease: "power3.inOut",
        }
      );

    // moon timeline
    this.moonTl = gsap.timeline();
    this.moonTl
      .fromTo(
        ".intro .moon",
        {
          rotation: -90,
        },
        {
          rotation: 0,
          immediateRender: true,
          delay: 0.5,
          duration: 3,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".intro .moon",
        {
          rotation: 0,
        },
        {
          rotation: 90,
          immediateRender: true,
          delay: 1,
          duration: 3,
          ease: "power3.inOut",
        }
      );

    this.sceneTl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        pin: true,
        start: "top top",
        end: "+=20000",
        scrub: true,
        // snap: {
        //   snapTo: () => {
        //     const labels = this.sceneTl.labels,
        //       duration = this.sceneTl.duration(),
        //       direction = this.sceneTl.scrollTrigger.direction,
        //       prev = this.sceneTl.previousLabel(),
        //       next = this.sceneTl.nextLabel();

        //     if (direction != -1) {
        //       return labels[next] / duration;
        //     } else if (prev) {
        //       return labels[prev] / duration;
        //     } else {
        //       return 0;
        //     }
        //   },
        //   duration: { min: 1, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
        //   delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
        //   ease: "power3", // the ease of the snap animation ("power3" by default)
        // },
        onEnter: () => {
          this.logScenePageView();
        },
        onEnterBack: () => {
          this.logScenePageView();
          AudioManager.play("Traffic");
        },
      },
    });

    this.sceneTl
      .add(this.moonTl, 0)
      .add(this.scrollTl, 0)
      .add(this.storyTl, 0)
      .add(this.staticStarsTl, 0)
      .add(this.ambientStarsTl, 0)
      .add(this.shootinStartTl, 0)
      .add(this.textTl, 0)
      .add(this.dayTl, 0)
      .add(this.sunTl, 0)
      // .add(this.raysTl, 0)
      .add(this.sectionTl, 0)
      .add(this.houseTl, 0)
      .addLabel("moonIn", 3.5)
      .addLabel("Text1", 12)
      .addLabel("Text2", 17)
      .addLabel("Text3", 24)
      .addLabel("Text4", 28.5)
      .addLabel("house", 32);
  },
};
</script>

<style lang="scss" scoped>
section {
  background-color: #1d1e35;
  color: #fff;
  position: relative;
  height: 100vh;
  // padding: 20% 5% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .shooting-star {
    position: absolute;
    width: 15vh;
    z-index: 999;
    top: -15vh;
    left: auto;
    right: -15vh;
  }

  .scroll-indicator {
    position: absolute;
    z-index: 999;
    width: 250px;
  }

  // .swipe,
  // .scroll,
  .arrow {
    width: 15px;
    margin-top: 15px;
  }

  .stars {
    position: absolute;
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      width: 100%;

      // @include break($tablet) {
      //   height: 100%;
      // }
    }
  }

  .moon {
    position: relative;
    z-index: 2;
    max-width: 240px;
    width: 100%;
    transform-origin: 50% calc(50vw + 100%);
    transform: rotate(-90deg);
    margin-bottom: 55px;
  }

  .little-paradowski-story {
    max-width: 275px;
  }

  .text {
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 3;
      padding: 0px 15px;
    }

    span {
      opacity: 0;
      display: inline-block;
      font-family: inherit;
      font-size: 5vw;
      color: white;
      line-height: 1.5;
      font-weight: 100;

      @include break($tablet) {
        font-size: 2vw;
      }
    }
  }

  .gradient {
    position: absolute;
    display: inline-block;
    height: 100vh;
    width: 100%;
    background: linear-gradient(transparent, #65452d, #f7931e);
    top: 80%;
    left: 0;
    opacity: 0;
  }

  .sun {
    position: absolute;
    left: -50vw;
    top: 90%;
    width: 200vw;
    opacity: 0;

    @include break($tablet) {
      width: 120vw;
      left: -10vw;
    }
  }

  .title {
    position: absolute;
    top: 37.5vh;
    margin: 0;
    font-family: fairwater-script, FairwaterScript-Bold, Fairwater Script;
    font-size: 12vw;
    line-height: 1;
    font-weight: 600;
    color: #21203D;
    opacity: 0;

    @include break($tablet) {
      top: 20vh;
    }

    @include break($desktop) {
      font-size: 135px;
    }
  }

  .byline {
    position: absolute;
    top: 42.5vh;
    opacity: 0;
    color: black;

    @include break($tablet) {
      top: 35vh;
    }
  }

  .house {
    position: absolute;
    top: 40vh;
    opacity: 0;
    bottom: 0;
    width: 100%;

    > svg {
      position: absolute;
      bottom: -5%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 90vw;
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    @include break($tablet) {
      top: 50vh;
    }
  }
}
</style>
