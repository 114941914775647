<template lang="pug">
SceneTemplate
  template(v-slot:illustrations)
    Man.frame.frame-1(width="83.08%", x="8.46%")
    TippingMan1.frame.frame-2(width="83.08%", x="8.46%", y="-7.55%")
    TippingMan2.frame.frame-3(width="83.08%", x="8.46%", y="-2.75%")
    TippingMan3.frame.frame-4(width="94%", x="5.89%", y="-10.6%")
  template(v-slot:text)
    p.frame(
      v-for="(copy, index) in text",
      :class="`frame-${index + 1}`",
      v-html="copy"
    )
</template>

<script>
import SceneTemplate from "./SceneTemplate";
import Man from "../assets/TippingScene/man.svg";
import TippingMan1 from "../assets/TippingScene/man-tipping-1.svg";
import TippingMan2 from "../assets/TippingScene/man-tipping-2.svg";
import TippingMan3 from "../assets/TippingScene/man-tipping-3.svg";
//@ts-check
import gsap from "../gsap";
import analytics from "../mixins/analytics";

export default {
  components: {
    Man,
    TippingMan1,
    TippingMan2,
    TippingMan3,
    SceneTemplate,
  },

  /**
   * @typedef {Object} SceneData
   * @property {gsap.core.Timeline} sceneTl
   * @property {string[]} text
   */

  /**
   * @returns {SceneData}
   */
  mixins: [analytics],
  data() {
    return {
      sceneTl: null,
      text: [
        "Out on the town?",
        "Don’t forget to tip.",
        "Don’t forget to tip.",
        "And round up.",
      ],
    };
  },
  mounted() {
    this.sceneTl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        start: "top top",
        end: "+10000",
        pin: true,
        scrub: true,
        onEnter: () => {
          this.logScenePageView();
        },
        onEnterBack: () => {
          this.logScenePageView();
        },
      },
    });

    const frameDuration = 0.5,
      frameDelay = 0.5,
      tEl = this.$el.querySelectorAll(".text-container");

    this.text.forEach((text, index) => {
      const frame = index + 1;
      const el = this.$el.querySelectorAll(`.frame-${frame}`);
      const gEl = this.$el.querySelectorAll(`svg.frame-${frame}`);
      const pEl = this.$el.querySelectorAll(`p.frame-${frame}`);

      this.sceneTl.to(
        gEl,
        {
          autoAlpha: 1,
          display: "block",
          duration: frameDuration,
        },
        "<"
      );

      if (text) {
        this.sceneTl.to(
          tEl,
          {
            opacity: 1,
            duration: frameDuration,
          },
          "<"
        );
      } else {
        this.sceneTl.to(
          tEl,
          {
            opacity: 0,
            duration: frameDuration,
          },
          "<"
        );
      }

      this.sceneTl.to(pEl, {
        autoAlpha: 1,
        display: "block",
        duration: frameDuration,
      });

      this.sceneTl.to(
        el,
        {
          display: "none",
          autoAlpha: 0,
          duration: frameDuration,
          delay: frameDelay,
        },
        ">"
      );
    });

    this.sceneTl.to(
      tEl,
      {
        opacity: 0,
        duration: frameDuration,
      },
      "<"
    );
  },
};
</script>

<style lang="scss" scoped>
section {
  .frame {
    display: none;
    opacity: 0;
  }
}
</style>
<style lang="scss">
#TippingScene {
  .text-container {
    opacity: 0;
  }
}
</style>
