function TrackManager(label) {
	this.audio = new Audio(require(`../public/${label}.mp3`));
	this.audio.loop = true;
	this.fadeInterval = null;

	this.play = () => {
		// console.info(`play ${label}`);

		return this.audio.play();
	}

	this.pause = () => {
		// console.info(`pause ${label}`);

		return this.audio.pause();
	}

	this.start = () => {
		this.reset();
		return this.play()
	}

	this.reset = () => {
		if (this.fadeInterval) clearInterval(this.fadeInterval);

		this.pause();
		this.audio.currentTime = 0;
		this.audio.volume = 1;
	};

	return {
	audio: this.audio,
	play: this.play,
	pause: this.pause,
	start: this.start,
	reset: this.reset,
	fade: (interval = 250, increment = 0.05) => {
		if (this.fadeInterval) clearInterval(this.fadeInterval);
		
		const maxIterations = 1 / increment;

		if (!this.audio.paused && this.audio.currentTime) {
			// console.info(`fade ${label}`);
			let iteration = 0;
			this.fadeInterval = setInterval(() => {

				// limit iterations fallback
				// iOS Safari doesn't permit setting audio volume
				iteration++;

				const volume = this.audio.volume - increment;
				this.audio.volume = volume.toFixed(2);

				if (this.audio.volume === 0 || iteration >= maxIterations) {
					this.reset()
					}
				}, interval)
			}
		}
	}
}

function AudioManager() {
	this.trackManagers = {
		Wind: new TrackManager('Wind'),
		Traffic: new TrackManager('Traffic'),
		Instrumental: new TrackManager('Instrumental')
	};

	this.stage = () => {
		for (const [key, trackManager] of Object.entries(this.trackManagers)) {
			trackManager.play()
				.then(() => {
					if (this.muted || this.currentTrack && this.currentTrack !== key) {
						trackManager.reset()
					}
				})
				.catch(() => {
					// console.error(error)
				});
		}

		this.staged = true;
	};

	this.staged = false;
	this.muted = true;
	this.currentTrack = null;

	return {
		stage: this.stage,
		play: (label, force = false) => {
			for (const [key, trackManager] of Object.entries(this.trackManagers)) {
				if (key === label) {
					if (this.currentTrack && this.currentTrack !== label) {
						trackManager.reset()
					}
					
					this.currentTrack = label;

					if (trackManager.audio.paused && !trackManager.audio.currentTime) {
						if (!force && !this.muted) {
							trackManager.play().catch(e => console.error(e));
						}
					}
				}
				else {
					trackManager.fade();
				}
			}
		},
		mute: () => {
			this.muted = true;

			for (const [key, trackManager] of Object.entries(this.trackManagers)) {
				if (this.currentTrack && key == this.currentTrack) {
					trackManager.pause();
				} else {
					trackManager.reset();
				}
			}
		},
		unmute: () => {
			this.muted = false;

			if (!this.staged) this.stage()
			if (this.currentTrack) {
				return this.trackManagers[this.currentTrack].play();
			}
		}
	}
}

export default new AudioManager();