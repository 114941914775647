<template lang="pug">
SceneTemplate.row
  template(v-slot:illustrations)
    Office1.frame.frame-1
    g.frame.frame-2
      Office2
      Office1(width='45%' x='-2%' y='30%')
    Office3.frame.frame-3
  template(v-slot:text)
    p.frame(
      v-for="(copy, index) in text",
      :class="`frame-${index + 1}`",
      v-html="copy"
    )
</template>

<script>
import SceneTemplate from "./SceneTemplate";
import Office1 from "../assets/OfficeScene/office-1.svg";
import Office2 from "../assets/OfficeScene/office-2.svg";
import Office3 from "../assets/OfficeScene/office-3.svg";
//@ts-check
import gsap from "../gsap";
import analytics from "../mixins/analytics";

export default {
	components: {
		Office1,
		Office2,
		Office3,
		SceneTemplate,
  },
  /**
   * @typedef {import('../../scenes.d').SceneData} SceneData
   */

  /**
   * @returns {SceneData}
   */
  mixins: [analytics], 
  data() {
		return {
			sceneTl: null,
			text: [
				"Back at your gig,<br> small talk feels big.",
				"",
				"See Diamond? See Nate?<br> Their chatter sounds great.",
			]
		}
	},
	mounted() {
		this.sceneTl = gsap.timeline({
			scrollTrigger: {
				trigger: this.$el,
				start: 'top top',
				end: '+7500',
				pin: true,
        scrub: true,
        onEnter: () => {
          this.logScenePageView();
        },
        onEnterBack: () => {
          this.logScenePageView();
        }
			}
    });
    
    const frameDuration = 0.5,
      frameDelay = 0.5,
      tEl = this.$el.querySelectorAll('.text-container');

    this.text.forEach((text, index) => {
      const frame = index + 1;
      const el = this.$el.querySelectorAll(`.frame-${frame}`);

      this.sceneTl.to(el, {
          display: "flex",
          autoAlpha: 1,
          duration: frameDuration,
        }, ">");

        if (text) {
          this.sceneTl.to(tEl, {
            opacity: 1,
            duration: frameDuration,
          }, "<");
        } else {
          this.sceneTl.to(tEl, {
            opacity: 0,
            duration: frameDuration,
          }, "<");
        }

        this.sceneTl.to(el, {
          display: "none",
          autoAlpha: 0,
          duration: frameDuration,
          delay: frameDelay,
        }, ">");
    });
    
    this.sceneTl.to(tEl, {
      opacity: 0,
      duration: frameDuration,
    }, "<");
	}
}
</script>

<style lang="scss" scoped>
section {
  .frame {
    display: none;
    opacity: 0;
  }
}
</style>

<style lang="scss">
#OfficeScene {
  .text-container {
    opacity: 0;
  }
}
</style>