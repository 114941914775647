<template lang="pug">
#app
  aside.audio-toggle
    AudioOff(@click="unmute" v-show="muted")
    AudioOn(@click="mute" v-show="!muted")
  IntroScene#IntroScene(page-title="Home (Intro)" page-path="/")
  BedroomScene#BedroomScene(page-title="Bedroom" page-path="/bedroom")
  CommuteScene#CommuteScene(page-title="Smile" page-path="/smile")
  OfficeScene#OfficeScene(page-title="Small Talk" page-path="/talk")
  TippingScene#TippingScene(page-title="Tip?" page-path="/tip")
  OutroScene#OutroScene(page-title="Listen, Stories" page-path="/together")
</template>

<script>
import AudioOff from "./assets/audio-off.svg";
import AudioOn from "./assets/audio-on.svg";
import BedroomScene from "./components/BedroomScene.vue";
import CommuteScene from "./components/CommuteScene.vue";
import IntroScene from "./components/IntroScene.vue";
import OfficeScene from "./components/OfficeScene.vue";
import OutroScene from "./components/OutroScene.vue";
import TippingScene from "./components/TippingScene.vue";
import AudioManager from "./AudioManager";

export default {
  name: "App",
  components: {
    AudioOff,
    AudioOn,
    BedroomScene,
    CommuteScene,
    IntroScene,
    OfficeScene,
    OutroScene,
    TippingScene,
  },
  data() {
    return {
      muted: true
    }
  },
  methods: {
    mute() {
      this.muted = true;
      AudioManager.mute();
    },
    unmute() {
      this.muted = false;
      AudioManager.unmute();
    }
  },
  mounted() {
    // set initial audio to wind
    AudioManager.play('Wind');
  }
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/mcw6rqo.css");

#app {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body,
html {
  margin: 0;
  padding: 0;
  border: 0;
}

#app * {
  box-sizing: border-box;
}

section {
  height: 100vh;
}

p,
text {
  font-family: clarendon-urw;
  font-size: 1.5625rem;
  line-height: 1.18;
  font-weight: 300;

  @include break($desktop) {
    line-height: 1.357;
  }
}

.audio-toggle {
  position: fixed;
  z-index: 10;
  top: 21px;
  right: 21px;
  height: 31.5px;
  width: 31.5px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;

  @include break($desktop) {
    top: 30px;
    right: 30px;
    height: 45px;
    width: 45px;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
