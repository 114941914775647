const AnalyticsMixin = {
    props: {
        pageTitle: {
            type: String,
            required: true,
        },
        pagePath: {
            type: String,
            required: true
        }
    },
    methods: {
        /**
         * Sends a virtual page view using the gtag
         * 
         * @param {String} page_title The title of the page. (defaults to document.title)
         * @param {String} page_location The URL of the page. (defaults to location.href)
         * @param {String} page_path THe path to the page. (defaults to location.pathname)
         */
        logVirtualPageView(page_title, page_location, page_path) {
            console.log(`Firing page view for: ${page_title} at ${page_location}`);
            window.gtag('event', 'page_view', {
                page_title,
                page_location,
                page_path
            });

        },
        logScenePageView() {
            this.logVirtualPageView(this.pageTitle, window.location.origin + this.pagePath, this.pagePath);
        }
    }
}

export default AnalyticsMixin;