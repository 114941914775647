<template lang="pug">
SceneTemplate
  template(v-slot:illustrations)
    g.background
      Snow.snow(width="198.07%", height="541.4%", x="-50%", y="-489.6%")
    g.foreground
      g.frame.frame-1
        People1.people-1(width="74.77%", x="12.62%", y="-9%")
      g.frame.frame-2
        People2.people-2(width="74.77%", x="12.62%", y="-9%")
      g.frame.frame-3
        People3.people-3(width="74.77%", x="12.62%", y="-9%")
        g.text-1
          text.mobile(width="44.5%", x="29%", y="38%") &ldquo;I missed you.&rdquo;
          text.desktop(width="34.5%", x="38%", y="38%") &ldquo;I missed you.&rdquo;
      g.frame.frame-4
        HappyHolidays(width="78.99%", x="10.51%", y="-18%")
        text.text-friends(width="34.5%", x="32.75%", y="62%", fill="#4E4EEA") From your friends at
        a(href="https://paradowski.com/", target="_blank", rel="noreferrer")
          ParadowskiLogo(width="36.56%", x="31.72%", y="22%")
            rect(height="100%", width="100%", fill="transparent")
        topButton.top-button(width="20%", x="40%", y="42%", @click="toTop")
          rect(height="100%" width="100%" fill="transparent")
  template(v-slot:text)
    p.frame(
      v-for="(copy, index) in text"
      :class="`frame-${index + 1}`"
    )
      span.line(v-for="(line, index) in copy"
        :class="`line-${index + 1}`"
        v-html="line"
      )
</template>

<script>
import SceneTemplate from "./SceneTemplate";
import Snow from "../assets/OutroScene/snow.svg";
import People1 from "../assets/OutroScene/people-1.svg";
import People2 from "../assets/OutroScene/people-2.svg";
import People3 from "../assets/OutroScene/people-3.svg";
import HappyHolidays from "../assets/OutroScene/happy-holidays.svg";
import ParadowskiLogo from "../assets/OutroScene/paradowski-logo.svg";
import topButton from "../assets/OutroScene/ui-back-to-top.svg";

//@ts-check
import gsap from "../gsap";
import analytics from "../mixins/analytics";

export default {
  /**
   * @typedef {Object} SceneData
   * @property {gsap.core.Timeline} sceneTl
   * @property {string[]} text
   */

  /**
   * @returns {SceneData}
   */
  components: {
    SceneTemplate,
    Snow,
    People1,
    People2,
    People3,
    HappyHolidays,
    ParadowskiLogo,
    topButton,
  },
  mixins: [analytics],
  data() {
    return {
      sceneTl: null,
      text: [
        [
          `
            And when you can finally visit,<br>
            listen to all the stories.<br>
          `,
          `Again.`
        ],
        [
          `
            When you hold hands, hold on a little tighter.<br>
            And don’t forget to offer the simplest, most important phrase of all ...
          `
        ],
        [],
      ],
    };
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.sceneTl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        start: "top top",
        end: "+10000",
        pin: true,
        scrub: true,
        onEnter: () => {
          this.logScenePageView();
        },
        onEnterBack: () => {
          this.logScenePageView();
        },
      },
    });

    const backgroundTl = gsap.timeline();

    backgroundTl.to(this.$el.querySelector(".snow"), {
      attr: { y: "0%" },
      duration: 6,
    });

    const illustrationTl = gsap.timeline();

    const frameDuration = 0.5,
      frameDelay = 0.5;
    // frameLength = frameDuration * 2 + frameDelay;

    this.text.forEach((copy, index) => {
      const frame = index + 1;
      const el = this.$el.querySelectorAll(`.frame-${frame}`);

      const gEl = this.$el.querySelectorAll(`g.frame-${frame}`);
      const pEl = this.$el.querySelectorAll(`p.frame-${frame}`);

      illustrationTl.to(gEl, {
        autoAlpha: 1,
        display: "block",
        duration: frameDuration,
      }, "<" );

      if (copy.length) {
        illustrationTl.to(this.$el.querySelector(".text-container"), {
          autoAlpha: 1,
          opacity: 1,
          duration: frameDuration,
        }, "<" );
      } else {
        illustrationTl.to(this.$el.querySelector(".text-container"), {
          autoAlpha: 0,
          opacity: 0,
          duration: frameDuration,
        }, "<");
      }

      illustrationTl.to(pEl, {
        autoAlpha: 1,
        display: "block",
        duration: frameDuration,
      });

      // if multiple lines
      if (copy.length) {
        copy.forEach((line, lineIndex) => {
          // after the first line
          if(lineIndex) {
            const lEl =  this.$el.querySelector(`p.frame-${frame} .line-${lineIndex + 1}`);

            illustrationTl.to(lEl, {
              autoAlpha: 1,
              display: 'block',
              duration: frameDuration
            }, ">");
          }
        });
      }

      if (frame == 3) {
        const tEl = this.$el.querySelector(`g.frame-${frame} .text-1`);

        illustrationTl.to(tEl, {
          autoAlpha: 1,
          display: "block",
          duration: frameDuration,
        }, "<");

        illustrationTl.to(tEl, {
          autoAlpha: 1,
          display: "block",
          duration: frameDelay,
        });
      }

      illustrationTl.to(el, {
        autoAlpha: 0,
        display: "none",
        duration: frameDuration,
        delay: frameDelay,
      });
    });
    // end forEach

    illustrationTl.add(() => {
      const direction = this.sceneTl.scrollTrigger.direction;
      if (direction === -1) {
        this.$el
          .querySelector(".illustration-container")
          .classList.remove("centered");
      } else {
        this.logVirtualPageView(
          "Final",
          window.location.origin + "/end",
          "/end"
        );
        this.$el
          .querySelector(".illustration-container")
          .classList.add("centered");
      }
    });

    illustrationTl.to(this.$el.querySelectorAll(`.frame-4`), {
      autoAlpha: 1,
      display: "block",
      duration: frameDuration,
    });

    this.sceneTl.add(backgroundTl).add(illustrationTl, "<");
  },
};
</script>

<style lang="scss" scoped>
section {
  background-color: #1d1e35;

  @include break($desktop) {
    color: #fcf3eb;
  }

  .top-button {
    position: absolute;
    z-index: 999;
    cursor: pointer;
  }

  .frame,
  .text-1 {
    display: none;
    opacity: 0;
  }

  .line {
    opacity: 0;
  }

  .line-1 {
    display: block;
    opacity: 1;
  }

  .text-1 {
    // not supported by Safari
    // transform: scale(1.89) translateY(7%);
    // transform-origin: center center;

    .mobile {
      font-size: 2.953125rem;
      display: block;

      @include break($desktop) {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include break($desktop) {
        display: block;
      }
    }
  }
}
</style>
<style lang="scss">
#OutroScene {
  .text-container {
    opacity: 0;
  }

  .illustration-container.centered {
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
