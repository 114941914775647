<template lang="pug">
SceneTemplate.row-reverse
  template(v-slot:illustrations)
    Commuters.frame.frame-1
    OKGuy.frame.frame-2(width="68.95%" x="10%")
    NotOkGuy.frame.frame-3(width="62.97%" x="10%")
    HappyGuy.frame.frame-4(width="62.97%" x="10%")
  template(v-slot:text)
    p.frame(
      v-for="(copy, index) in text",
      :class="`frame-${index + 1}`",
      v-html="copy"
    )
</template>

<script>
import SceneTemplate from "./SceneTemplate.vue";
import Commuters from "../assets/CommuteScene/Commuters.svg";
import HappyGuy from "../assets/CommuteScene/HappyGuy.svg";
import NotOkGuy from "../assets/CommuteScene/NotOkGuy.svg";
import OKGuy from "../assets/CommuteScene/OKGuy.svg";
import gsap from "../gsap";
import analytics from "../mixins/analytics";

export default {
  components: {
    Commuters,
    HappyGuy,
    NotOkGuy,
    OKGuy,
    SceneTemplate,
  },

  /**
   * @typedef {import('../../scenes.d').SceneData} SceneData
   */

  /**
   * @returns {SceneData}
   */
  mixins: [analytics], 
  data() {
    return {
      sceneTl: null,
      text: [
        "When masks are but a memory,<br>you’ll see smiles on the train.",
        "Those are teeth.<br/> Isn’t that neat?",
        "Go ahead, show them yours.",
        "",
      ],
    };
  },
  mounted() {
    this.sceneTl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        start: "top top",
        end: "+10000",
        pin: true,
        scrub: true,
        onEnter: () => {
          this.logScenePageView();
        },
        onEnterBack: () => {
          this.logScenePageView();
        }
      },
    });

    const frameDuration = 0.5,
      frameDelay = 0.5,
      tEl = this.$el.querySelectorAll('.text-container');

    this.text.forEach((text, index) => {
      const frame = index + 1;
      const el = this.$el.querySelectorAll(`.frame-${frame}`);

      // Crossfade to frame 4
      if (frame == 4) {
        const gEl = this.$el.querySelectorAll(`svg.frame-${frame}`);
        const pEl = this.$el.querySelectorAll(`p.frame-${frame}`);

        this.sceneTl.to(gEl, {
          autoAlpha: 1,
          display: 'block',
          duration: frameDuration,
        }, '<');
        
        this.sceneTl.to(pEl, {
          autoAlpha: 1,
          display: 'block',
          duration: frameDuration,
        });

        if (text) {
          this.sceneTl.to(tEl, {
            opacity: 1,
            duration: frameDuration,
          }, "<");
        } else {
          this.sceneTl.to(tEl, {
            opacity: 0,
            duration: frameDuration,
          }, "<");
        }

        this.sceneTl.to(el, {
          autoAlpha: 0,
          display: 'none',
          duration: frameDuration,
          delay: frameDelay
        });
        
      } else {
        this.sceneTl.to(el, {
          display: "flex",
          autoAlpha: 1,
          duration: frameDuration,
        }, ">");

        if (text) {
          this.sceneTl.to(tEl, {
            opacity: 1,
            duration: frameDuration,
          }, "<");
        } else {
          this.sceneTl.to(tEl, {
            opacity: 0,
            duration: frameDuration,
          }, "<");
        }
  
        this.sceneTl.to(el, {
          display: "none",
          autoAlpha: 0,
          duration: frameDuration,
          delay: frameDelay,
        }, ">");
      }
    });

    this.sceneTl.to(tEl, {
      opacity: 0,
      duration: frameDuration,
    }, "<");
  }
};
</script>

<style lang="scss" scoped>
section {
  .frame {
    display: none;
    opacity: 0;
  }
}
</style>
