<template lang="pug">
SceneTemplate.column
  template(v-slot:illustrations)
    g.frame.frame-1
      Bed.bed(width="68.28%", x="5.85%", y="0")
      NightStand.nightstand(width="37.72%", x="73.82%", y="10%")
      Man1.man-1(width="17.44%", x="52.57%")
    g.frame.frame-2
      Socks(width="8.24%", x="25.65%", y="18%")
      Underwear(width="12.41%", x="16.44%", y="2%")
      Watch(width="6.9%", x="21.99%", y="-9%")
      Shirt1(width="18.62%", x="10.8%", y="-20%")
      Shirt2(width="19.52%", x="67.54%", y="-16%")
      Comb(width="7.91%", x="86.97%", y="-5%")
      Pants(width="16.92%", x="67.76%", y="11%")
      Man2.man-2(width="23.45%", x="38.28%")
    g.frame.frame-3
      Man3.man-3(width="22.4%", x="38.8%")
    g.frame.frame-4
      Man4.man-4(width="43.69%", x="28.98%", y="-8.45%")
    g.frame.frame-5
      circle.bubble-1(cx="37%", cy="27%", r="1.5%", fill="white")
      circle.bubble-2(cx="46%", cy="24%", r="2.75%", fill="white")
      Shoes1.shoes-1(width="13.79%", x="52.56%", y="-22%")
      Shoes2.shoes-2(width="13.26%", x="71.85%", y="-11%")
      Shoes3.shoes-3(width="13.41%", x="48.39%", y="-5%")
      Cat1.cat-1(width="29.78%", x="53%", y="18%")
      Woman1.woman-1(width="22.49%", x="17%")
    g.frame.frame-6
      Cat2.cat-2(width="29.78%", x="53%", y="18%")
      Woman2.woman-2(width="22.49%", x="17%")
    g.frame.frame-7
      Cat3.cat-3(width="29.78%", x="53%", y="18%")
      Woman3.woman-3(width="22.49%", x="17%")
  template(v-slot:text)
    p.frame(
      v-for="(copy, index) in text",
      :class="`frame-${index + 1}`",
      v-html="copy"
    )
</template>

<script>
import SceneTemplate from "./SceneTemplate";
// 1
import Bed from "../assets/BedroomScene/a/bed.svg";
import NightStand from "../assets/BedroomScene/a/nightstand.svg";
import Man1 from "../assets/BedroomScene/a/man-1.svg";
// 2
import Man2 from "../assets/BedroomScene/b/man-2.svg";
import Socks from "../assets/BedroomScene/b/socks.svg";
import Underwear from "../assets/BedroomScene/b/underwear.svg";
import Watch from "../assets/BedroomScene/b/watch.svg";
import Shirt1 from "../assets/BedroomScene/b/shirt-1.svg";
import Shirt2 from "../assets/BedroomScene/b/shirt-2.svg";
import Comb from "../assets/BedroomScene/b/comb.svg";
import Pants from "../assets/BedroomScene/b/pants.svg";
// 3
import Man3 from "../assets/BedroomScene/c/man-3.svg";
// 4
import Man4 from "../assets/BedroomScene/d/man-4.svg";
// 5
import Shoes1 from "../assets/BedroomScene/e/shoes-1.svg";
import Shoes2 from "../assets/BedroomScene/e/shoes-2.svg";
import Shoes3 from "../assets/BedroomScene/e/shoes-3.svg";
import Cat1 from "../assets/BedroomScene/e/cat-1.svg";
import Woman1 from "../assets/BedroomScene/e/woman-1.svg";
// 6
import Cat2 from "../assets/BedroomScene/f/cat-2.svg";
import Woman2 from "../assets/BedroomScene/f/woman-2.svg";
// 7
import Cat3 from "../assets/BedroomScene/g/cat-3.svg";
import Woman3 from "../assets/BedroomScene/g/woman-3.svg";

import AudioManager from "../AudioManager";

import gsap from "../gsap";
import analytics from "../mixins/analytics";

export default {
  /**
   * @typedef {Object} SceneData
   * @property {gsap.core.Timeline} sceneTl
   * @property {string[]} text
   */

  /**
   * @returns {SceneData}
   */
  components: {
    SceneTemplate,
    // a
    Bed,
    NightStand,
    Man1,
    // b
    Socks,
    Underwear,
    Watch,
    Shirt1,
    Shirt2,
    Comb,
    Pants,
    Man2,
    // c
    Man3,
    // d
    Man4,
    // e
    Shoes1,
    Shoes3,
    Shoes2,
    Cat1,
    Woman1,
    // f
    Cat2,
    Woman2,
    // g
    Cat3,
    Woman3,
  },
  mixins: [analytics],
  data() {
    return {
      sceneTl: null,
      text: [
        "",
        "When returning to work,<br> you’ll need your entire attire.",
        "Shirts are happiest tucked. And when it comes to pants, don’t slack.",
        "Slack.",
        `
          Trouble with shoes? Pair browns with blues.
          <br/>
          <br class="mobile-only"/>
          Now, let’s get something on those feet.
        `,
        "Both feet.",
        `
          And don’t worry.
          <br/>
          <br class="mobile-only"/>
          The cat will enjoy the alone time.
        `,
      ],
    };
  },
  mounted() {
    this.sceneTl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        start: "top top",
        end: "+17500",
        pin: true,
        scrub: true,
        onEnter: () => {
          this.logScenePageView();
          AudioManager.play("Instrumental");
        },
        onEnterBack: () => {
          this.logScenePageView();
        },
      },
    });

    const frameDuration = 0.5,
      frameDelay = 0.5,
      tEl = this.$el.querySelectorAll(".text-container");

    this.text.forEach((text, index) => {
      const frame = index + 1;
      const el = this.$el.querySelectorAll(`.frame-${frame}`);

      // Crossfade to frames 4, 6, and 7
      if (frame == 4 || frame == 6 || frame == 7) {
        const gEl = this.$el.querySelectorAll(`g.frame-${frame}`);
        const pEl = this.$el.querySelectorAll(`p.frame-${frame}`);

        this.sceneTl.to(
          gEl,
          {
            autoAlpha: 1,
            display: "block",
            duration: frameDuration,
          },
          "<"
        );

        this.sceneTl.to(pEl, {
          autoAlpha: 1,
          display: "block",
          duration: frameDuration,
        });

        if (text) {
          this.sceneTl.to(
            tEl,
            {
              opacity: 1,
              duration: frameDuration,
            },
            "<"
          );
        } else {
          this.sceneTl.to(
            tEl,
            {
              opacity: 0,
              duration: frameDuration,
            },
            "<"
          );
        }

        this.sceneTl.to(el, {
          autoAlpha: 0,
          display: "none",
          duration: frameDuration,
          delay: frameDelay,
        });
      } else {
        this.sceneTl.to(el, {
          autoAlpha: 1,
          display: "block",
          duration: frameDuration,
        });

        if (text) {
          this.sceneTl.to(
            tEl,
            {
              opacity: 1,
              duration: frameDuration,
            },
            "<"
          );
        } else {
          this.sceneTl.to(
            tEl,
            {
              opacity: 0,
              duration: frameDuration,
            },
            "<"
          );
        }

        this.sceneTl.to(el, {
          autoAlpha: 0,
          display: "none",
          duration: frameDuration,
          delay: frameDelay,
        });
      }
    });

    this.sceneTl.to(
      tEl,
      {
        opacity: 0,
        duration: frameDuration,
      },
      "<"
    );
  },
};
</script>

<style lang="scss" scoped>
section {
  background: #fcf3eb;
  position: relative;
}

.frame {
  display: none;
  opacity: 0;
}

[class^="copy-"] {
  display: none;
}

.mobile-only {
  display: block;

  @include break($desktop) {
    display: none;
  }
}
</style>
